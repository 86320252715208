.option-method-card {
  transition: all 0.3s ease;
}

.option-method-card:hover {
  box-shadow: 0 0 0 2px var(--color-primary-light-red);
}

.toggle-switch {
  width: 37px;
  height: 20px;
}

.toggle-switch > div {
  width: 37px !important;
  height: 20px !important;
  background-color: var(--color-neutral-black) !important;
}

.toggle-switch > div > p {
  width: 17px !important;
  height: 17px !important;
  padding: 0 9px !important;
}

.container {
  border-bottom: 0.3px solid var(--color-neutral-gray-divider);
}
.input-discount-code {
  min-width: 250px !important;
}
.input-discount-code > div > svg > circle {
  fill: var(--color-feedback-success-light) !important;
}
