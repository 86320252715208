.banner_container div,
.banner_container div picture,
.banner_container div picture img {
  width: 100% !important;
  height: inherit !important;
  overflow: hidden;
}

.banner_container div picture img {
  object-fit: fill;
  overflow: hidden;
}
