.radioSelector {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.radioSelector > input {
  display: none;
}

.radioSelector > label::before {
  width: 18px;
  height: 18px;
}
