.card_container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.delete_card {
  background-color: var(--color-background-primary-light);
  opacity: 0.2;
}
