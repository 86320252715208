.option-method-card {
  transition: all 0.3s ease;
}

.option-method-card_active {
  box-shadow: 0 0 0 1px var(--color-primary-light-red);
}

.option-method-card [id^='cards__delete'] > svg {
  cursor: pointer !important;
}

.toggle-switch {
  width: 37px;
  height: 20px;
}

.toggle-switch > div {
  width: 37px !important;
  height: 20px !important;
}

.toggle-switch > input:not(:checked) + div {
  background-color: var(--color-neutral-black) !important;
}

.toggle-switch > div > p {
  width: 17px !important;
  height: 17px !important;
  padding: 0 9px !important;
}

.container {
  border-bottom: 0.3px solid var(--color-neutral-gray-divider);
}

.container_header {
  min-height: 68px !important;
  border-bottom: 0.3px solid var(--color-neutral-gray-divider);
}

.container_body,
.container_body--deleteCard {
  height: 100% !important;
}
.container_body--deleteCard {
  padding: 0 24px !important;
}
#modalDeleteCardMobile-mobile-modal-wrapper__footer {
  box-shadow: 0 !important;
}

.paymentMethods__cardIcon {
  cursor: pointer;
}

.modalUnipayMobile__body {
  height: calc(100% - 180px) !important;
}

/* small only */
@media (width <= 39.9375em) {
  .container_header {
    min-height: 48px !important;
  }
}
