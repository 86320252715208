.toggle-switch {
  width: 37px;
  height: 20px;
}

.toggle-switch > div {
  width: 37px !important;
  height: 20px !important;
}

.toggle-switch > input:not(:checked) + div {
  background-color: var(--color-neutral-black) !important;
}

.toggle-switch > div > p {
  width: 17px !important;
  height: 17px !important;
  padding: 0 9px !important;
}

.instalment-btn {
  gap: 4px;
  color: var(--color-neutral-gray-dark);
  user-select: none;
}

.instalment-btn > svg {
  width: 16px;
  height: 16px;
  cursor: pointer;
  rotate: -90deg;
}

.instalment-btn > svg > path {
  fill: var(--color-neutral-gray-dark);
}

.instalment-btn:hover {
  background: var(--color-background-gray-light);
}
