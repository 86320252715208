.toggle-switch {
  width: 52px;
  height: 28px;
}

.toggle-switch > div {
  width: 52px !important;
  height: 28px !important;
}

.toggle-switch > input:not(:checked) + div {
  background-color: var(--color-neutral-black) !important;
}

.toggle-switch > div > p {
  width: 24px !important;
  height: 24px !important;
  padding: 0 13px !important;
}
